<template>
  <div class="wrap">
    <el-scrollbar style="height:100%">
    <div class="content">
      <div class="fs-20 py-30">
        <strong>{{ id ? '编辑设计' : '新建设计'}}</strong>
        <hr />
      </div>
      <div class="article-title fs-20">
        <div class="py-10">标题：</div>
        <input
          class="title-input fs-17 mb-10 pl-15"
          type="text"
          v-model="article.title"
          placeholder="请输入文章标题"
        />
      </div>
      <!-- <div class="article-cate">
        <div class="py-10 fs-20">所属分类：</div>
        <div class="d-flex">
          <div
            class="categories-item fs-15 p-10 px-30 m-10 m-c-p"
            :class="item.class"
            v-for="item in categories"
            :key="item._id"
            @click="cate(item, $event)"
          >
            {{ item.name }}
          </div>
        </div>
      </div> -->
      <div class="public">
          <div class="py-10 fs-20">是否公开：</div>
          <div class="d-flex">
              <div class="categories-item fs-15 p-10 px-30 m-10 m-c-p" :class="{active: publicOr}" @click="publicOr = !publicOr">是</div>
              <div class="categories-item fs-15 p-10 px-30 m-10 m-c-p" :class="{active: !publicOr}" @click="publicOr = !publicOr">否</div>
          </div>
      </div>
      <div class="design-cover">
          <div class="py-10 fs-20">选择封面：</div>
          <div>
              <ImgCutter @cutDown="cutDown" :boxWidth="390" :boxHeight="290" :cutWidth="260" :cutHeight="195">
                <button class="select-image fs-15 p-10 px-30 m-10 m-c-p" slot="open">Select image</button>
              </ImgCutter>
              <div>
                <div v-if="!article.cover" class="update-image ml-10">封面预览</div>
                <img v-else :src="article.cover" alt="">
              </div>
          </div>
      </div>
      <div class="article-content">
        <div class="py-10 fs-20">内容：</div>
        <vue-editor
          id="editor"
          useCustomImageHandler
          @image-added="handleImageAdded"
          v-model="article.content"
        ></vue-editor>
      </div>
      <div>
        <button
          class="add-article mt-20 px-30 py-10 fs-17 m-c-p"
          @click="addArticle"
        >
          {{ id ? '确认修改' : '添加设计'}}
        </button>
        <span class="fs-15 ml-30 tip">{{ tip }}</span>
      </div>
    </div>
    </el-scrollbar>
  </div>
</template>

<script>
import ImgCutter from 'vue-img-cutter'
import { VueEditor } from "vue2-editor";
export default {
  props:['id'],
  data() {
    return {
      publicOr: false,
      tip:'',
      article: {
        title: "",
        content: "",
        userId: this.userid,
        categories: [],
        publicOr: '',
        cover: ''
      },
    };
  },
  components: {
    VueEditor,
    ImgCutter
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      const formData = new FormData();
      formData.append("file", file);

      this.$axios({
        url: "/image/upload",
        method: "POST",
        data: formData,
      })
        .then((result) => {
          let url = result.data.url; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    classRes(id) {
      return this.article.categories.indexOf(id) >= 0;
    },
    async addArticle(){
        if(!this.article.title){
            this.tip = "× 请输入标题"
            setTimeout(() => {this.tip = ''}, 3000)
            return
        }else if(!this.article.cover){
            this.tip = "× 请选择封面"
            setTimeout(() => {this.tip = ''}, 3000)
            return
        }else if(!this.article.content){
            this.tip = "× 请输入内容"
            setTimeout(() => {this.tip = ''}, 3000)
            return
        }
        const model = Object.assign({}, this.article, {userId: this.userid, publicOr: this.publicOr})
        if(!this.id){
          const result = await this.$axios.post('/addDesign', model)
          if(result.data.message = 'success'){
            this.$router.push('/design')
            this.$message({type: 'success', message: '添加成功'})
          }
        }else{
          const result = await this.$axios.put(`/addDesign/${this.id}`, model)
          if(result.data.message = 'success'){
            this.$router.push('/user')
            this.$message({type: 'success', message: '修改成功'})
          }
        }
    },
    cutDown(item){
        const formData = new FormData();
        formData.append("file", item.file);

        this.$axios({
            url: "/image/upload",
            method: "POST",
            data: formData,
        })
        .then((result) => {
            let url = result.data.url; // Get url from response
            this.article.cover = url
        })
        .catch((err) => {
            console.log(err);
        });
    },
    async getDesignInfo(){
      const result = await this.$axios.get(`/rest/Design/${this.id}`)
      this.article = result.data
      this.publicOr = result.data.publicOr
    }
  },
  computed:{
      userid(){
          return this.$store.state.user._id
      },
  },
  mounted() {
    this.id &&  this.getDesignInfo()
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/variables";
.wrap {
  margin-top: 5rem;
  height: 100vh;
  .content {
    width: 140rem;
    margin: 0 auto;
    transform: translate(-9rem, 0);
    margin-bottom: 5rem;
    .article-title {
      .title-input {
        border: none;
        height: 5rem;
        width: 100rem;
        border: 1px solid map-get($colors, "border-grey");
        border-radius: 1rem;
      }
    }
    .categories-item {
        border: 1px solid map-get($colors, "border-light");
        border-radius: 0.3rem;
        &.active {
            background: map-get($colors, "purple");
            color: white;
            border: 1px solid transparent;
        }
    }
    .select-image{
        background: map-get($colors, 'purple' );
        border: none;
        color: white;
        border-radius: 0.3rem;
    }
    .update-image{
        width: 15rem;
        height: 15rem;
        border: 1px solid  map-get($colors, 'border-light' );
        border-radius: 1rem;
        text-align: center;
        line-height: 15rem;
        font-size: 2.5rem;
        color: map-get($colors, 'border-light' );
    }
    .add-article {
      background: map-get($colors, "purple");
      border: none;
      color: white;
      border-radius: 0.5rem;
    }
  }
  .tip{
      color: red;
  }
}
</style>